import { CircleNotch } from "phosphor-react";

interface Props {
  type?: "button" | "submit";
  children?: React.ReactNode;
  bgColor?: string;
  textColor?: string;
  className?: String;
  disabled?: boolean;
  isLoading?: boolean;
  onClick?: () => void;
  tooltipTitle?: string;
  rounded?: boolean;
  variant?: ButtonVariant;
  disabledOpacity?: number;
}

type ButtonVariant = "contained" | "text" | "link" | "danger" | "outlined";

const getVariantClassName = (variant?: ButtonVariant) => {
  switch (variant) {
    case "contained":
      return "bg-primary-button-blue text-white";
    case "text":
      return "bg-white !text-primary-text";
    case "link":
      return "bg-white !text-holocene-blue";
    case "danger":
      return "bg-danger text-white";
    case "outlined":
      return "bg-white !text-holocene-blue !border !border-holocene-blue";
    default:
      return "";
  }
};

const Button: React.FC<Props> = ({
  type = "button",
  children,
  variant,
  bgColor = "bg-primary-button-blue",
  textColor = "text-white",
  className = "",
  disabled,
  onClick,
  isLoading = false,
  tooltipTitle,
  rounded = false,
  disabledOpacity = 70,
}) => {
  const variantClassName = getVariantClassName(variant);
  return (
    <button
      type={type}
      className={`inline-flex items-center ${
        disabled ? `opacity-${disabledOpacity}` : ""
      } px-4 py-2 ${
        rounded ? "rounded-full" : "rounded-md"
      } text-sm font-medium ${variantClassName} ${textColor} ${bgColor} focus:outline-none ${className}`}
      onClick={onClick}
      disabled={disabled}
      data-tip={tooltipTitle}
    >
      {isLoading ? <CircleNotch className={`animate-spin h-5 w-5 ${textColor}`} /> : children}
    </button>
  );
};

export default Button;
