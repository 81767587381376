import apiInstance from "holocene-services/axios";
import {
  GetNylasMailsResponse,
  NylasMailThread,
  PatchNylasMailsReadStatusPayload,
  PostNylasMailPayload,
  SendNylasMailPayload,
} from "./types";

class NylasService {
  async getNylasMails(
    skip: number,
    take: number,
    shipmentId: number
  ): Promise<GetNylasMailsResponse> {
    const response = await apiInstance.get(
      `/nylas/mails?skip=${skip}&take=${take}&shipmentId=${shipmentId}`
    );
    return response.data;
  }

  async getNylasMailsAttachments(messageId: string, fileId: string) {
    const response = await apiInstance.get(`/nylas/mails/${messageId}/files/${fileId}`);
    return response.data;
  }

  async getNylasStatus(): Promise<{ status: "connected" | "disconnected" | "expired" }> {
    const response = await apiInstance.get(`/nylas/active`);
    return response.data;
  }

  async nylasDisconnect() {
    const response = await apiInstance.delete(`/nylas/logout`);
    return response.data;
  }

  async nylasConnect() {
    const response = await apiInstance.get(`/nylas/login`);
    return response.data;
  }

  async patchNylasMailsReadStatus(payload: PatchNylasMailsReadStatusPayload) {
    return apiInstance.patch("/nylas/mails", payload);
  }

  async postNylasMail(payload: FormData) {
    return apiInstance.post("/nylas/mails", payload);
  }

  async deleteNylasMail(payload: string[]) {
    return apiInstance.delete("/nylas/mails", { data: { nylasThreadIds: payload } });
  }

  async nylasReply(values: FormData) {
    const response = await apiInstance.post(`/nylas/mails`, values);
    return response.data;
  }

  async getEmailsBySubject(params: {
    subject: string;
    nextCursor?: string;
    withSummary?: boolean;
  }) {
    return apiInstance.get(`/nylas/mails-by-subject`, { params: params }).then(
      (res) =>
        res.data as {
          summary: { detail: string | null; intent: string; summary: string | null } | null;
          threads: NylasMailThread[];
          nextCursor?: string;
          summaryDate?: string;
        }
    );
  }

  async sendMail(body: SendNylasMailPayload) {
    const formData = nylasService.prepareNylasFormData(body);
    return apiInstance.post("/nylas/send-mail", formData);
  }

  prepareNylasFormData(body: SendNylasMailPayload) {
    const formData = new FormData();
    formData.append("subject", body.subject);
    formData.append("emailBody", body.body);
    if (body.threadId) formData.append("threadId", body.threadId.toString());

    if (body.to && body.to.length > 0) {
      formData.append("recipients", body.to.join(","));
    }
    if (body.cc && body.cc.length > 0) {
      formData.append("cc", body.cc?.join(","));
    }

    if (body.bcc && body.bcc.length > 0) {
      formData.append("bcc", body.bcc.join(","));
    }

    if (body.files && body.files.length > 0) {
      for (let i = 0; i < body.files.length; i++) {
        formData.append("files[]", body.files[i]);
      }
    }
    return formData;
  }
}

export const nylasService = new NylasService();
