import React from "react";
import { useForm } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";

import Button from "holocene-components/common/Button";
import Input from "holocene-components/common/new/Input";

import { ICreateDeliveryTaskRequest } from "holocene-services/deliveryTasks.service";
import DatePicker from "holocene-components/common/new/DatePicker";
import { CalendarBlank } from "phosphor-react";
import UserAvatar from "holocene-components/common/UserAvatar";
import { useAuth } from "@frontegg/nextjs";
import moment from "moment";
import { useGetUserInfo } from "holocene-hooks/users.hooks";

interface Props {
  onAdd: (values: ICreateDeliveryTaskRequest) => void;
}

const DeliveryTaskForm: React.FC<Props> = ({ onAdd }) => {
  const { user } = useAuth();
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm<ICreateDeliveryTaskRequest>({
    defaultValues: {
      dueDate: null,
      task: "",
    },
  });

  const handleAdd = async (values: ICreateDeliveryTaskRequest) => {
    await onAdd({ ...values, task: values.task.trim() });
    reset();
  };
  const dueDate = watch("dueDate");
  console.log(user);
  return (
    <form
      className="py-4 px-6 flex flex-col gap-3 items-end border"
      onSubmit={handleSubmit(handleAdd)}
    >
      <div className="w-full">
        <div className="grow">
          <Input
            name="task"
            typeOfInputfield="textarea"
            register={register("task", {
              required: "Task is required",
              validate: (value) => !!value.trim() || "Task is required",
            })}
            errors={errors}
            className="resize-none"
          />
        </div>
      </div>
      <div className="flex w-full items-center">
        <div className="w-[140px] h-9 mr-4">
          <DatePicker
            label=""
            handleDateChange={(date) => {
              setValue("dueDate", date ? moment(date).format("YYYY-MM-DD") : null);
            }}
            value={dueDate ? moment(dueDate).toDate() : null}
            icon={<CalendarBlank size={20} className="text-holocene-blue" weight="bold" />}
            iconPosition="left"
            showIcon
          />
        </div>
        <div className="grow">
          <UserAvatar name={user?.name || user?.email || ""} />
        </div>
        <div className="">
          <Button type="submit" className="h-10" onClick={handleSubmit(handleAdd)}>
            Add Task
          </Button>
        </div>
      </div>
    </form>
  );
};

export default DeliveryTaskForm;
