import axios, { AxiosHeaders } from "axios";
import { routes } from "holocene-constants/routes";
import { authHeader } from "./auth-header";
import { operationModuleHeader } from "./operation-module-header";
import { clearLocalStorage } from "holocene-utils/common.utils";

const apiInstance = axios.create({
  baseURL: `${process.env.Base_URL}/api/v1`,
});

apiInstance.interceptors.request.use((req) => {
  const headers = new AxiosHeaders();

  const customHeaders = {
    ...authHeader(),
    ...operationModuleHeader(),
  };

  Object.entries(customHeaders).forEach(([key, value]) => {
    headers.set(key, value);
  });

  if (req.headers) {
    Object.entries(req.headers).forEach(([key, value]) => {
      headers.set(key, value);
    });
  }

  req.headers = headers;

  return req;
});

apiInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401) {
      clearLocalStorage();
      window.location.href = window.location.origin + routes.logout;
    }
    return Promise.reject(error);
  }
);

export default apiInstance;
