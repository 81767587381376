import React, { CSSProperties, ReactNode, useEffect, useRef, useState } from "react";
import { X } from "phosphor-react";
import ReactTooltip from "react-tooltip";
import { LoadingSpinner } from "../LoadingSpinner";
import { ModalContainer } from "./ModalContainer";

export interface IModalProps {
  title?: string;
  subtitle?: string;
  showModal: boolean;
  setShowModal: (state: boolean) => void;
  className?: string;
  style?: CSSProperties;
  disableCloseOnOutsideClick?: boolean;
  hideClose?: boolean;
  // Set to true to prevent the autofocus that is set on the first element of a form inside the modal
  focusTitle?: boolean;
  titleClassName?: string;
  headerClassName?: string;
  showLoading?: boolean;
  autoHeight?: boolean;
  children?: ReactNode;
}

const Modal: React.FC<IModalProps> = ({
  children,
  title = "",
  subtitle = "",
  showModal,
  setShowModal,
  className,
  style = {},
  disableCloseOnOutsideClick,
  hideClose,
  focusTitle = false,
  titleClassName = "text-lg",
  headerClassName = "",
  showLoading = false,
  autoHeight = true,
}) => {
  const id = useRef(Math.random());
  const modalContainerId = `modal_container_${id.current}`;
  const modalHeaderId = `modal_header_${id.current}`;
  const [show, setShow] = useState(false);
  const [childrenContainerHeight, setChildrenContainerHeight] = useState<number | undefined>();

  useEffect(() => {
    // This is done, because when showModal is true by default,
    // transitions are skipped and the modal height computation
    // does not work as expected
    setShow(showModal);
  }, [showModal]);

  useEffect(() => {
    if (showModal && !autoHeight) {
      ReactTooltip.rebuild();
      setTimeout(() => {
        const modalContainerHeight = document
          .getElementById(modalContainerId)
          ?.getBoundingClientRect().height;
        const modalHeaderHeight = document
          .getElementById(modalHeaderId)
          ?.getBoundingClientRect().height;
        const offset = 50;
        const childrenContainerHeight = modalContainerHeight! - modalHeaderHeight! - offset;
        setChildrenContainerHeight(childrenContainerHeight);
      }, 100);
    }
  }, [showModal, autoHeight]);

  const willChildrenRenderCorrectly = autoHeight || childrenContainerHeight !== undefined;
  return (
    <ModalContainer
      showModal={show}
      setShowModal={setShowModal}
      disableCloseOnOutsideClick={disableCloseOnOutsideClick}
      id={modalContainerId}
      style={style}
      className={className}
      hideClose={hideClose}
    >
      <div className={`flex justify-between mb-2 ${headerClassName}`} id={modalHeaderId}>
        <div className="w-full">
          {title && (
            <div
              className={`leading-6 font-semibold text-primary-text flex items-center gap-2 ${titleClassName}`}
              tabIndex={focusTitle ? 1 : -1}
            >
              {title}
              {showLoading && (
                <div>
                  <LoadingSpinner size={16} />
                </div>
              )}
            </div>
          )}
          {subtitle && (
            <div className="text-xs leading-5 font-normal text-secondary-text mt-2">{subtitle}</div>
          )}
        </div>
      </div>
      {willChildrenRenderCorrectly && (
        <div style={{ height: childrenContainerHeight }}>{children}</div>
      )}
    </ModalContainer>
  );
};

export default Modal;
