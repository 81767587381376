import { AxiosHeaders, AxiosRequestHeaders } from "axios";
import { getLocalStorageItem } from "holocene-utils/common.utils";

export function operationModuleHeader(): AxiosRequestHeaders {
  try {
    const urlActiveOperation = window.location.href.replace(/https?:\/\//, "").split("/")[1];
    const activeOperation =
      urlActiveOperation === "sales"
        ? "sales"
        : urlActiveOperation === "purchase"
        ? "purchase"
        : getLocalStorageItem("activeOperation");

    if (typeof activeOperation !== "string") throw new Error("Active operation module not found");

    const headers = new AxiosHeaders();
    headers.set("X-Operation-Module", activeOperation);
    return headers;
  } catch {
    return {} as AxiosRequestHeaders;
  }
}
