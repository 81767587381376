import React, { useContext, useEffect, useState } from "react";
import { useAuth, useAuthActions } from "@frontegg/nextjs";
import { useGetCustomerDetails } from "holocene-hooks/users.hooks";
import { ICountry } from "holocene-services/user.service";
import { ModuleEnum } from "holocene-components/settings/UserDetailsModal";

export interface IUserDetailsContext {
  isLoading: boolean;
  availableCustomerTypes: { id: number; type: string }[];
  selectedCustomerTypeId: number;
  customerId: number;
  selectedCustomerType: { id: number; type: string } | undefined;
  operationType?: ModuleEnum;
  shortName: string | undefined;
  country: ICountry | undefined;
  currency: string;
  isSidebarCollapsed: boolean;
  setSidebarCollapsed: (isCollapsed: boolean) => void;
}

type IUserDetailsProvider = {
  children: React.ReactNode;
};

export enum IOperation {
  importer = "purchase",
  exporter = "sales",
}
declare type IOperationType = keyof typeof IOperation;

export const UserContext = React.createContext<IUserDetailsContext>({
  isLoading: false,
  availableCustomerTypes: [],
  selectedCustomerTypeId: 0,
  customerId: 0,
  selectedCustomerType: undefined,
  operationType: undefined,
  shortName: undefined,
  country: undefined,
  currency: "",
  isSidebarCollapsed: true,
  setSidebarCollapsed: () => {},
});

export const useUserDetails = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUserDetails must be in UserDetailsProvider");
  }
  return context;
};

export const UserDetailsProvider: React.FC<IUserDetailsProvider> = (props) => {
  const { data: customers, isLoading } = useGetCustomerDetails();

  const [availableCustomerTypes, setAvailableCustomerTypes] = useState<
    { id: number; type: string }[]
  >([]);
  const [selectedCustomerTypeId, setSelectedCustomerTypeId] = useState<number>(0);
  const [customerId, setCustomerId] = useState<number>(0);
  const [country, setCountry] = useState<ICountry>();
  const [shortName, setShortName] = useState<string>();
  const [selectedCustomerType, setSelectedCustomerType] = useState<
    { id: number; type: string } | undefined
  >();
  const [currency, setCurrency] = useState<string>("");
  const [isSidebarCollapsed, setSidebarCollapsed] = useState(true);

  const { user } = useAuth();

  useEffect(() => {
    if (!customers) {
      return;
    }

    const availableTypes = customers.availableCustomerTypes || [];
    setAvailableCustomerTypes(customers.availableCustomerTypes);
    const type = availableTypes.find(
      (item: { id: number }) => item.id === customers.selectedCustomerTypeId
    );
    setSelectedCustomerTypeId(customers.selectedCustomerTypeId);
    setCustomerId(customers.id);
    setSelectedCustomerType(type);
    setShortName(customers.shortName ?? "");
    setCountry(customers.country);
    setCurrency(customers.currency.toUpperCase() ?? "");
  }, [customers]);

  useEffect(() => {
    localStorage.setItem("userInfo", JSON.stringify({ accessToken: user?.accessToken }));
  }, [user]);

  const operationType = selectedCustomerType?.type as IOperationType;
  return (
    <UserContext.Provider
      value={{
        availableCustomerTypes,
        selectedCustomerTypeId,
        customerId,
        selectedCustomerType,
        operationType:
          operationType === "importer"
            ? ModuleEnum.purchase
            : operationType === "exporter"
            ? ModuleEnum.sales
            : undefined,
        isLoading,
        shortName,
        country,
        currency,
        isSidebarCollapsed,
        setSidebarCollapsed,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};
