import Button from "holocene-components/common/Button";
import { useGetNylasActive, useGetNylasStatus } from "holocene-hooks/nylas.hooks";
import { useGetUserInfo } from "holocene-hooks/users.hooks";
import { nylasService } from "holocene-services/nylas.service";
import { getLocalStorageItem } from "holocene-utils/common.utils";
import { X } from "phosphor-react";
import { useEffect, useState } from "react";

const nylasReauthPromptDismissedKey = "isNylasReauthPromptDismissed";
export const ReauthinticationPrompt = () => {
  const [isDismissed, setIsDismissed] = useState(
    () => getLocalStorageItem(nylasReauthPromptDismissedKey) === "true"
  );

  const { data: status, isLoading: statusLoading } = useGetNylasStatus();

  const handleNylasConnect = () => {
    nylasService.nylasConnect().then((response) => {
      window.open(response.redirectUrl, "_self");
    });
  };

  const handleDismiss = () => {
    setIsDismissed(true);
    localStorage.setItem(nylasReauthPromptDismissedKey, "true");
  };

  useEffect(() => {
    if (status === "connected") {
      localStorage.removeItem(nylasReauthPromptDismissedKey);
    }
  }, [status]);

  if (statusLoading || status !== "expired" || isDismissed) return null;

  return (
    <div className="fixed top-1 left-1/2 -translate-x-1/2 w-[500px] mx-auto rounded-full bg-ice-blue-2 text-holocene-blue py-2 px-4 justify-between text-sm flex gap-3">
      <div className="flex gap-3 items-center">
        Your email needs to be reauthenticated
        <Button variant="contained" className="text-xs !px-2 !py-0.5" onClick={handleNylasConnect}>
          Connect
        </Button>
      </div>
      <Button variant="link" className="!bg-transparent  !p-0" onClick={handleDismiss}>
        <X size={18} />
      </Button>
    </div>
  );
};
