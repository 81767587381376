import { AxiosHeaders, AxiosRequestHeaders } from "axios";
import { getLocalStorageItem } from "holocene-utils/common.utils";

type UserInfo = {
  accessToken: string;
};

export function authHeader(contentType: string | null = null): AxiosRequestHeaders {
  // Set this to whatever the minimum token length should be (if you know)
  // Otherwise, you can leave at 1 for "not an empty string"
  const minTokenLength = 1;

  try {
    const headers = new AxiosHeaders();
    const userInfo = getLocalStorageItem("userInfo");
    // Abort if not string
    if (typeof userInfo !== "string") throw new Error("User info not found");

    // Destructure token
    const { accessToken } = JSON.parse(userInfo) as UserInfo;

    // Abort if token is not string and min length
    if (!(typeof accessToken === "string" && accessToken.length >= minTokenLength)) {
      throw new Error("Invalid user access token");
    }
    if (contentType) {
      // I left this here because it seems like you weren't sure about which format you need:
      headers.set("Authorization", `Bearer ${accessToken}`);
      headers.set("content-type", contentType);
      return headers;
    }

    // I left this here because it seems like you weren't sure about which format you need:
    headers.set("Authorization", `Bearer ${accessToken}`);
    return headers;

    // Return headers object
    // return { 'x-access-token': accessToken };
  } catch {
    // Catch any errors and return an empty headers object
    return {} as AxiosRequestHeaders;
  }
}
