import { AxiosRequestConfig } from "axios";
import apiInstance from "holocene-services/axios";

import { TMimeType, convertFileToBlob, convertFileToPdfBlob } from "holocene-utils/helpers";

import { WebViewerInstance } from "@pdftron/webviewer";
import { MIME_TYPE } from "holocene-constants/documents.constants";

export const packingListVersions = ["Packing List", "Packing_List", "packing list", "packing_list"];

class ShipmentService {
  getShipmentsRequestController: AbortController | undefined;
  getShipmentsWithStatusRequestController: AbortController | undefined;

  async getShipmentDocumentsFile(s3Uri: string, token?: string) {
    const config: AxiosRequestConfig = {
      params: {
        s3Uri,
      },
    };
    if (token) {
      config.headers = {
        Authorization: token,
      };
    }
    const response = await apiInstance.get(`/shipment-documents/file`, config);
    return response?.data || { data: [] };
  }

  getShipmentFileBlob = (file: { location: string }) =>
    this.getShipmentDocumentsFile(file.location).then((res) => ({
      ...file,
      fileBlob: convertFileToPdfBlob(res.data),
    }));

  getFileBlob = (file: { location: string }) => {
    const ext = file.location.split(/[#?]/)[0]?.split(".")?.pop()?.trim() || "pdf";
    return this.getShipmentDocumentsFile(file.location).then((res) => ({
      ...(file as any),
      fileBlob: convertFileToBlob(res.data, `.${ext}` as TMimeType),
    }));
  };

  async searchShipments(search: string, pageParam: { skip: number; take: number }) {
    return apiInstance
      .get(`/shipments/search`, { params: { search, ...pageParam } })
      .then((res) => res.data);
  }
}

export default new ShipmentService();
